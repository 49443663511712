<template>
  <div class="write-class">
    <!-- banner -->
    <div class="banner">
      <img
        class="banner-bg"
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-banner.png"
        alt="写字课"
      >
      <div class="ban-content container">
        <div class="ban-left">
          <h5>河小象系统练字课</h5>
          <p>每天15分钟，在家提升孩子书写水平</p>
          <div class="line"></div>
          <span class="icon">科学专业的系统课程</span>
          <span class="icon">轻松的上课模式</span>
          <span class="icon">强大的师资力量</span>
          <div class="download">
            <img
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/download-write.png"
              class="download-qrcode"
            >
          </div>
          <span class="qrcode-download">扫码下载iOS/Andriod</span>
        </div>
        <!-- 预约 -->
        <div class="ban-right">
          <div class="tit">
            <p>免费领取</p>
            <p>
              <!-- 价值 -->
              <!-- <span>199元</span>--> 写字体验课 
            </p>
          </div>
          <div class="input" :style="namenull">
            <input
              type="text"
              v-model.trim="name"
              :placeholder="cname"
              @focus="disappear"
              @blur="appear"
            >
          </div>
          <div class="input grade" @click.stop="gradeshow=!gradeshow" :style="gradenull">
            <input v-model="grade" type="text" placeholder="请选择孩子的年级" disabled>
            <div class="imgchoose">
              <div class="downimg"></div>
            </div>
          </div>
          <div class="input" :style="cellphonenull">
            <span class="areacode">
              <p>+86</p>
            </span>
            <input
              v-model.trim="cellphone"
              :placeholder="cphone"
              @focus="pdisappear"
              @blur="pappear"
            >
          </div>
          <button class="appointBtn" @click.stop="order">立即预约</button>
          <!-- <button class="customService">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/custom-service.png">
            <span>咨询客服</span>
          </button>-->
          <!-- 下拉菜单 -->
          <transition name="select">
            <div class="selectMenu" v-show="gradeshow">
              <div class="gradeList">
                <div
                  @click="selectgrade(index)"
                  v-for="(item,index) in gradeList"
                  :key="index"
                >{{item.label}}</div>
              </div>
            </div>
          </transition>
          <transition name="slide-fade">
            <div class="message" v-if="showMessage">{{message}}</div>
          </transition>
        </div>
      </div>
    </div>
    <!-- video -->
    <!-- <div class="video">
      <div class="title-template container">
        <p>
          两分钟了解
          <span>河小象写字</span>
        </p>
        <div class="underline"></div>
        <div class="video-content">
          <div class="video-bg" @click="videoPlay" v-if="showVideoBg">
            <img
              class="bg"
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/video-bg.png"
              alt
            >
            <img
              class="icon"
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/play-icon.png"
              alt
            >
          </div>
          <video
            ref="writeClassVideo"
            class="video-player"
            :class="{hide: showVideoBg}"
            controls
            name="media"
            controlslist="nodownload"
          >
            <source
              src="https://v.hexiaoxiang.com/9d9333e5fe994b9fa03f77dc457e1c50/08583f9dfbc5407296c0b72588d53711-5287d2089db37e62345123a1be272f8b.mp4"
              type="video/mp4"
            >
          </video>
        </div>
      </div>
    </div> -->
    <!-- 专业平台 -->
    <div class="platform">
      <div class="title-template container">
        <p>
          <span>专业的</span>书法教育平台
        </p>
        <div class="underline"></div>
        <div class="platform-content">
          <div class="calligraphy">
            <img
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon1.png"
              alt
            >
            <div class="tit">全国知名书法家</div>
            <p>龚金夫，浙江省硬笔书法家协会理事，超过20年的书法教学经验。</p>
          </div>
          <div class="calligraphy">
            <img
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon2.png"
              alt
            >
            <div class="tit">自主研发课程体系</div>
            <p>自主研发的科学系统课程内容，真人一对一三维立体观看教学+辅导老师。笔画入门、单字、成语到古诗组成，课程编排科学有效。</p>
          </div>
          <div class="calligraphy">
            <img
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon3.png"
              alt
            >
            <div class="tit">一对一闪电辅导</div>
            <p>辅导老师均为本科书法专业或书法教学3年以上，当天作业1对1 及时点评辅导，问题不过夜，每时每刻老师就在身边</p>
          </div>
          <div class="calligraphy">
            <img
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon4.png"
              alt
            >
            <div class="tit">无忧退款承诺</div>
            <p>河小象承诺如果您不满意开课15天内我们无理由全额退款。这不仅仅是责任感，更是对我们自己专业的自信</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程体系 -->
    <div class="system">
      <div class="title-template container">
        <p>
          <span>科学的</span>课程体系
        </p>
        <div class="underline"></div>
        <div class="system-content">
          <div class="lower-grade">
            <div class="lower grade">中低级课程</div>
            <div class="class-level">
              <span class="level pink">启蒙课</span>
              <span class="level orange">初级上</span>
              <span class="level orange">初级下</span>
              <span class="level green">中级上</span>
              <span class="level green">中级下</span>
            </div>
            <div class="level-content">
              <div class="content content1 pink">
                <p>· 针对基础性笔画在独体字结构中的基本书写方法</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>1级</span>及以上
                </p>
              </div>
              <div class="content content2 orange">
                <p>· 核心掌握对于组合结构的基本书写方法及简单规律</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>2级</span>及以上
                </p>
              </div>
              <div class="content content3 orange">
                <p>· 核心掌握对于组合结构的基本书写方法及简单规律</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>2级</span>及以上
                </p>
              </div>
              <div class="content content4 green">
                <p>· 核心讲解曲线笔画在独体字及复合结构中的书写及应用</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>3级</span>及以上
                </p>
              </div>
              <div class="content content5 green">
                <p>· 核心讲解较为复杂的独体字及复合结构字形的书写及应用</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>3级</span>及以上
                </p>
              </div>
            </div>
          </div>
          <div class="higher-grade">
            <div class="higher grade">高级课程</div>
            <div class="class-level">
              <span class="level pink">阶段一</span>
              <span class="level pink">阶段二</span>
              <span class="level orange">阶段三</span>
              <span class="level orange">阶段四</span>
              <span class="level green">阶段五</span>
              <span class="level green">阶段六</span>
              <span class="level blue">阶段七</span>
              <span class="level blue">阶段八</span>
            </div>
            <div class="level-content">
              <div class="content content6 pink">
                <p>· 核心讲解结构方面独体字结构的简单规律</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>3级</span>及以上
                </p>
              </div>
              <div class="content content7 pink">
                <p>· 核心讲解结构中笔画之间的呼应关系及不同类型笔画的书写方法</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>3级</span>及以上
                </p>
              </div>
              <div class="content content8 orange">
                <p>· 核心讲解笔画居于竖中线或竖中线两侧时的重心和倾斜角度</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>4级</span>及以上
                </p>
              </div>
              <div class="content content9 orange">
                <p>· 核心讲解取势、多点和单字中笔画的长短对比关系</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>4级</span>及以上
                </p>
              </div>
              <div class="content content10 green">
                <p>· 核心讲解长短对比问题，掌握较难部首的基本书写方法</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>5级</span>及以上
                </p>
              </div>
              <div class="content content11 green">
                <p>· 核心讲解左斜右正、左右结构、上下结构以及笔画穿插的问题</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>5级</span>及以上
                </p>
              </div>
              <div class="content content12 blue">
                <p>· 核心讲解结构方面如左右结构的收放关系等结构特征</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>6级</span>及以上
                </p>
              </div>
              <div class="content content13 blue">
                <p>· 核心讲解结构方面如包围结构、特殊结构、复合结构等结构特征</p>
                <p>
                  · 目标：全国硬笔书法等级考试
                  <span>6级</span>及以上
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上课模式 -->
    <div class="class-pattern">
      <div class="title-template container">
        <p>
          <span>科学的</span>上课模式
        </p>
        <div class="underline"></div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/pattern-class.png" alt>
      </div>
    </div>
    <!-- 师资力量 -->
    <div class="teacher">
      <div class="title-template container">
        <p>
          <span>优秀的</span>师资力量
        </p>
        <div class="underline"></div>
        <div class="teacher-team">
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hx-teacher.png" alt>
            <div class="desc">
              <p class="tit">书法家协会会员</p>
              <p>师从知名书法家王家骥先生（中书协会员、书法专业教授）</p>
              <div class="name">海象老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hm-teacher.png" alt>
            <div class="desc">
              <p class="tit">陕西省书法家协会会员</p>
              <p>师从陕西知名书法家刘锁祥、姚国谨先生</p>
              <div class="name">河马老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fx-teacher.png" alt>
            <div class="desc">
              <p class="tit">山东省教育书法家协会会员</p>
              <p>中国美术学院考级中心考官、书法讲师</p>
              <div class="name">飞象老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/zz-teacher.png" alt>
            <div class="desc">
              <p class="tit">省级书法家协会会员</p>
              <p>书法本科毕业，主攻二王一脉。</p>
              <div class="name">栀子老师</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 学生作品 -->
    <div class="student-pro">
      <div class="title-template container">
        <p>
          最新学员
          <span>作品</span>
        </p>
        <div class="underline"></div>
        <transition name="slide1-fade" mode="out-in">
          <div v-if="showPros" class="pro-content">
            <div class="pro" v-for="(item, index) in patchList" :key="index">
              <img :src="item.photoUrl" class="showtop">
              <div class="stu">
                <div class="avatar">
                  <img :src="item.avatarUrl" alt>
                </div>
                <div class="stu-detail">
                  <span class="name">{{item.name}}</span>
                  <span class="stu-teacher">辅导老师：{{item.teacher}}</span>
                </div>
                <div class="stu-time">{{item.timestamp|changeTime}}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- 家长反馈 -->
    <div class="feedBack">
      <div class="title-template">
        <p>
          家长
          <span>反馈</span>
        </p>
        <div class="underline"></div>
      </div>
      <feedBack
        postImg="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fb-write.png"
        :list="feedBack"
        rgba="color: rgba(255, 136, 64, 1)"
        title="false"
      ></feedBack>
    </div>
    <quickTool :downloadShow="showData"></quickTool>
    <!-- 预约提示框 -->
    <div class="orderTip" v-if="showOrderInfo">
      <h1 class="orderTitle">{{orderTitle}}</h1>
      <p class="ordermessage">{{orderMsg}}</p>
      <div class="closeOrder" @click.stop="showOrderInfo=false"></div>
    </div>
    <img
      src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/brush-btn.png"
      class="brush-btn"
      @click="openMask"
    >
    <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>

<script>
import feedBack from "../components/feedBack";
import quickTool from "@/components/quickTool";
import enterMask from "@/components/enterMask";
export default {
  name: "writeClass",
  components: {
    feedBack,
    quickTool,
    enterMask
  },
  data() {
    return {
      open: false,
      url: "",
      feedBack: [
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLVwjvW3QicGf0J4SE2H8Kl8wWIA1LKoyJAAyiaOd8OhWibFwPL0cAZiaQoQwb2gQubZyrzZAbw2HmWfg/132",
          name: "赵子萱妈妈",
          grade: "三年级",
          desc:
            "我家女儿是18年1月17日开始学的河小象，第一个月就有很大的进步。开学第一天老师就发现她练字了，并把语文书拿到投影仪让全班同学看。六月份硬笔书法还考过了三级，现在开始练习钢笔字了。孩子非常感谢河小象对她的帮助，并且会一直坚持练习下去。"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqIxLOFg15wThxZJZOTARjqtJH4IcjP3zWib1hAsAMSl34tNPDiaDHmWqQhwd09zMgL78XqGURau6Ww/132",
          name: "蒋天潇妈妈",
          grade: "一年级",
          desc:
            "孩子开始练字的时候是刚上大班没多久，很多字都不认识，边认边写。认字简单写字难，哪怕是最简单的“一”字想要写好也很困难。最开始，每个字都要写一个小时。但是现在已经通过了硬笔书法3级考试，每天一个字的练习时间不超过30分钟。对笔画和字的构成也都熟练多了。真感谢河小象让我的孩子有了如此大的进步！ "
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLWLzKfe5icOUU328sgv2YhDpc6NibMaicw0ScoGMcaLEd0EIvbEjrRZTC4rvUbiajztibL0cgkMXIqFNA/132",
          name: "朱依萱妈妈",
          grade: "大班",
          desc:
            " 我们家依萱是五个月前报的河小象。快上小学了，想给孩子练练字。她虽然才读大班，但是每日上写字课的时候必做的一件事：先在自己的本子上操练。自从学了河小象，她对写字越来越感兴趣了！ 现在我每天都在朋友圈发她的练字打卡作品。每天都有进步，真是打心底开心！"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoyBgb36pKU4mkZWpdsYdA2ZWpzibkK2wjP6PtV1RVVIdBdNx2ztbvmdCYwDWRzH5CkEvWxIC9gy7w/132",
          name: "闫珑缤妈妈",
          grade: "一年级",
          desc:
            "河小象是非常专业的线上书法平台，有着专业的书法老师和点评团队，孩子受益匪浅。珑缤在18年4月9日开始学习河小象，当初是由于我们工作忙，无暇顾及孩子，给他报了名。没想到效果出奇的好，他写的字越来越好了。上小学后作业也很快能完成，每次都是半小时内完成。从一开始不认字、不会写字，到后来了解笔画、结构，简单的字能写出笔锋了！ "
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEIck0iaicncuBvBVTEPRJ6jicPF8Ex8ibcUSTUrseFQx60YjLc8AZ6hAS11BQ4yupkPJQ2eARvNDOFOeg/132",
          name: "姜睿东妈妈",
          grade: "二年级",
          desc:
            "我儿子是上了一年级之后加入了河小象，得到了班主任和木瓜老师的鼓励之后，更加用心地练字。他每天坚持练字，逐渐养成了自己的练字习惯。练习的时候，自己觉得不行，会擦掉重写，再不满意再擦，直到写到满意为止。之前陪着他一起练，给他提些意见，后来就不用常常监督了，他自己会注意要点。孩子很感谢木瓜老师的引导，在这里谢谢老师了！"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/PiajxSqBRaELrf1coD424lKbicVmla4hNAvcia5l2A1vEgiaxu9euR9vkusXdetSSG8jukicR5WekvvwNRaLA5C7TYQ/132",
          name: "雷顺欣妈妈",
          grade: "二年级",
          desc:
            "我们家孩子在18年1月份报了名。经过几个月的练习，她最近好像顿悟了一样。以前作业考卷上的字都是乱七八糟的，现在我觉得她写得格外好看！我问她：“怎么进步如此之快？”她说：“提笔即练字。”孩子终于开窍了，感谢河小象！ "
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLVwjvW3QicGf0J4SE2H8Kl8wWIA1LKoyJAAyiaOd8OhWibFwPL0cAZiaQoQwb2gQubZyrzZAbw2HmWfg/132",
          name: "赵子萱妈妈",
          grade: "三年级",
          desc:
            "我家女儿是18年1月17日开始学的河小象，第一个月就有很大的进步。开学第一天老师就发现她练字了，并把语文书拿到投影仪让全班同学看。六月份硬笔书法还考过了三级，现在开始练习钢笔字了。孩子非常感谢河小象对她的帮助，并且会一直坚持练习下去。"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqIxLOFg15wThxZJZOTARjqtJH4IcjP3zWib1hAsAMSl34tNPDiaDHmWqQhwd09zMgL78XqGURau6Ww/132",
          name: "蒋天潇妈妈",
          grade: "一年级",
          desc:
            "孩子开始练字的时候是刚上大班没多久，很多字都不认识，边认边写。认字简单写字难，哪怕是最简单的“一”字想要写好也很困难。最开始，每个字都要写一个小时。但是现在已经通过了硬笔书法3级考试，每天一个字的练习时间不超过30分钟。对笔画和字的构成也都熟练多了。真感谢河小象让我的孩子有了如此大的进步！ "
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLWLzKfe5icOUU328sgv2YhDpc6NibMaicw0ScoGMcaLEd0EIvbEjrRZTC4rvUbiajztibL0cgkMXIqFNA/132",
          name: "朱依萱妈妈",
          grade: "大班",
          desc:
            " 我们家依萱是五个月前报的河小象。快上小学了，想给孩子练练字。她虽然才读大班，但是每日上写字课的时候必做的一件事：先在自己的本子上操练。自从学了河小象，她对写字越来越感兴趣了！ 现在我每天都在朋友圈发她的练字打卡作品。每天都有进步，真是打心底开心！"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoyBgb36pKU4mkZWpdsYdA2ZWpzibkK2wjP6PtV1RVVIdBdNx2ztbvmdCYwDWRzH5CkEvWxIC9gy7w/132",
          name: "闫珑缤妈妈",
          grade: "一年级",
          desc:
            "河小象是非常专业的线上书法平台，有着专业的书法老师和点评团队，孩子受益匪浅。珑缤在18年4月9日开始学习河小象，当初是由于我们工作忙，无暇顾及孩子，给他报了名。没想到效果出奇的好，他写的字越来越好了。上小学后作业也很快能完成，每次都是半小时内完成。从一开始不认字、不会写字，到后来了解笔画、结构，简单的字能写出笔锋了！ "
        }
      ],
      name: null, // input姓名
      grade: null, // input年级
      proList: [],
      patchList: [],
      showPros: true,
      showData: false, // 显示下载
      verifyPhone: "",
      timeNum: 60,
      message: "姓名不能为空",
      showMessage: false,
      showOrderInfo: false,
      orderTitle: "预约成功",
      orderMsg: "稍后我们会有老师来联系您激活课程，请保持电话畅通！",
      clicked: false, // 是否点击过预约
      gradeshow: false,
      gradeList: [
        {
          value: "1",
          label: "一年级"
        },
        {
          value: "2",
          label: "二年级"
        },
        {
          value: "3",
          label: "三年级"
        },
        {
          value: "4",
          label: "四年级"
        },
        {
          value: "5",
          label: "五年级"
        },
        {
          value: "6",
          label: "六年级"
        },
        {
          value: "7",
          label: "小班"
        },
        {
          value: "8",
          label: "中班"
        },
        {
          value: "9",
          label: "大班"
        },
        {
          value: "10",
          label: "其他"
        }
      ],
      cname: "请输入您家孩子的名字",
      cphone: "请输入您的手机号",
      cellphone: "",
      gradenull: "",
      namenull: "",
      cellphonenull: "",
      showVideoBg: true
    };
  },
  created() {
    this.getWorksInfo();
  },
  watch: {
    $route(to, from) {
      this.gradeshow = false;
    }
  },
  methods: {
    // 播放视频
    videoPlay() {
      this.showVideoBg = false;
      let video = this.$refs.writeClassVideo;
      let that = this;
      video
        .play()
        .then(res => {
          video.addEventListener("pause", () => {
            that.showVideoBg = true;
          });
          video.addEventListener('seeking',()=>{
            that.showVideoBg=false
          })
        })
        .catch(err => {});
    },
    openMask() {
      this.open = true;
      this.url =
        "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/art-brush.png";
    },
    //定期更新展示的作品
    setNewWork() {
      let that = this;
      let i = 1; // 第一组
      let len = this.proList.length;
      let num = parseInt(len / 3); // 三个一组
      let changePro = setInterval(() => {
        if (i < num) {
          that.showPros = false;
          let startNum = 3 * i;
          that.patchList = this.proList.slice(i * 3, i * 3 + 3);
          i = i + 1;
          setTimeout(() => {
            that.showPros = true;
          }, 800);
        } else {
          i = 0;
        }
      }, 8000);
    },
    // 获取作品信息
    getWorksInfo() {
      this.axios
        .post("/api/task/recommend", { per_page: 30 })
        .then(res => {
          if (res.data.code == 20000) {
            let tasks =  res.data.data.tasks.filter (item=>{
              return (new Date().getTime() - item.timestamp) < 3* 24 * 60 * 60* 1000
              });  
            this.proList = tasks.reduce(function(pre, val) {
              let obj = {};
              obj.avatarUrl = val.avatarUrl;
              if(val.nickName==null){
                obj.name=null
              }else{
              obj.name =
                val.nickName.length > 5
                  ? val.nickName.slice(0, 4) + "..."
                  : val.nickName;
              }
              obj.photoUrl = val.photoUrl;
              obj.teacher = val.teacher;
              obj.timestamp=val.timestamp
              pre.push(obj);
              return pre;
            }, []);
            this.patchList = this.proList.slice(0, 3);
            this.showWorks = true;
            this.setNewWork();
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //头部下拉选择年级
    selectgrade(index) {
      this.gradeshow = false;
      this.grade = this.gradeList[index].label;
    },
    //设置message
    setMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },
    //聚焦时清空名字
    disappear() {
      this.cname = " ";
    },
    //失去焦点时恢复默认输入为“请输入您家孩子的名字”
    appear() {
      this.cname = "请输入您家孩子的名字";
    },
    //同上，手机
    pdisappear() {
      this.cphone = " ";
    },
    //同上，手机
    pappear() {
      this.cphone = "请输入您的手机号";
    },
    // 预约
    order() {
      let nick_name = this.name;
      let array = [
        "",
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "小班",
        "中班",
        "大班",
        "其他"
      ];
      this.gradenum = array.indexOf(this.grade);
      let grade = this.gradenum;
      let cellphone = this.cellphone;
      // let source = this.getQueryString("source") || "公司"; //来源
      if (nick_name === null || nick_name === "") {
        this.message = "姓名不能为空";
        this.setMessage();
        this.nullname();
        return;
      }
      if (grade == -1 || grade === "") {
        this.message = "年级不能为空";
        this.setMessage();
        this.nullgrade();
        return;
      }
      if (cellphone == null || cellphone === "") {
        this.message = "手机号不能为空";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      if (!this.isPoneAvailable(cellphone)) {
        this.message = "请输入正确的手机号";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      grade = grade - 0;
      let source = localStorage.getItem("source") || "公司"; //this.$route.query.source||'公司'
      let params = { nick_name, grade, cellphone, source };
      let that = this;
      this.axios
        .post("/api/user/add/info", params)
        .then(res => {
          if (res.data.code == 20000) {
            that.orderTitle = "预约成功";
            that.orderMsg =
              "稍后我们会有老师来联系您激活课程，请保持电话畅通！";
            that.showOrderInfo = true;
          } else {
            if (res.data.code == 20001) {
              that.message = "此手机号已预约";
              that.setMessage();
              return;
            }
            if (res.data.code == 50000) {
              that.orderTitle = "预约失败";
              that.orderMsg = "服务端错误";
              that.showOrderInfo = true;
              return;
            }
            that.message = res.data.errMsg;
            that.setMessage();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getQueryString(name) {
      let reg = `(^|&)${name}=([^&]*)(&|$)`;
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 判断是否为手机号
    isPoneAvailable: function(pone) {
      var myreg = /^1[0-9]{10}$/; ///^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(pone)) {
        return false;
      } else {
        return true;
      }
    },
    //名字为空时变红
    nullname() {
      this.namenull = "border:1px red solid";
      setTimeout(() => {
        this.namenull = "";
      }, 1500);
    },
    //同上年级
    nullgrade() {
      this.gradenull = "border:1px red solid";
      setTimeout(() => {
        this.gradenull = "";
      }, 1500);
    },
    //同上电话
    nullcellphone() {
      this.cellphonenull = "border:1px red solid";
      setTimeout(() => {
        this.cellphonenull = "";
      }, 1500);
    }
  },
  filters:{
    changeTime(valueTime){
       var result;
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = now - valueTime;
    if(diffValue < 0){
    return;
  }
    var monthC =diffValue/month;
    var weekC =diffValue/(7*day);
    var dayC =diffValue/day;
    var hourC =diffValue/hour;
    var minC =diffValue/minute;
    if(monthC>=1){
    if(monthC<=12)
          result="" + parseInt(monthC) + "月前";
    else{
      result="" + parseInt(monthC/12) + "年前";
    }
    }
    else if(weekC>=1){
        result="" + parseInt(weekC) + "周前";
    }
    else if(dayC>=1){
        result=""+ parseInt(dayC) +"天前";
    }
    else if(hourC>=1){
        result=""+ parseInt(hourC) +"小时前";
    }
    else if(minC>=1){
        result=""+ parseInt(minC) +"分钟前";
    }else{
    result="刚刚";
  }
    return result;
    
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 1120px;
  margin: 0 auto;
}
.download {
  border-radius: 24px;
  padding: 10px;
  width: 140px;
  height: 140px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.download-qrcode {
  width: 130px;
  height: 140px;
  background: rgba(255, 255, 255, 1);
  border-radius: 12px;
  margin: 10px 0;
}
.qrcode-download {
  color: #fff;
  font-size: 16px;
  display: block;
  margin-top: 9px;
  margin-left: 7px;
}
.title-template {
  text-align: center;
  padding-top: 63px;
  > p {
    font-size: 50px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: rgba(68, 65, 68, 1);
    line-height: 55px;
    span {
      color: rgba(255, 136, 64, 1);
    }
  }
  .underline {
    width: 60px;
    height: 10px;
    background: rgba(255, 136, 64, 1);
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 60px;
  }
}
.pink {
  background: rgba(255, 135, 122, 1);
}
.orange {
  background: rgba(254, 157, 112, 1);
}
.green {
  background: rgba(109, 212, 219, 1);
}
.blue {
  background: rgba(89, 195, 243, 1);
}
.write-class {
  width: 100%;
  min-width: 1120px;
  text-align: left;
  .banner {
    width: 100%;
    position: relative;
    .ban-content {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 80px 20px 0;
      box-sizing: border-box;
      position: absolute;
      display: flex;
      justify-content: space-between;
      .ban-left {
        h5 {
          font-size: 54px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 60px;
          margin-bottom: 8px;
        }
        p {
          font-size: 32px;
          font-family: MicrosoftYaHeiLight;
          font-weight: 300;
          color: rgba(255, 255, 255, 1);
          line-height: 60px;
        }
        .line {
          width: 90px;
          height: 4px;
          background: rgba(255, 255, 255, 1);
          border-radius: 2px;
          margin: 26px 0;
        }
        span.icon {
          display: block;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 40px;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAmCAMAAACbFsmhAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAF3RSTlMAsPgYEAN2M+5Bzb/folLGB2Qj1ICKjpB1sToAAAF3SURBVDjLhVRZloQgDAwIAjabK/e/6RhARME39dGNsUyKIgGgwjKbSVIqJzMv0AW3v1DhZ3nLUWt4YVUvij9CB4evOSxXcqMavB/U6HJNVnGmGDKV2sXE0FRYPubRCh5SlY65ror7O3OtYU8PIub17YZ9VCbiGgXJoeccw4oTLi3SVd9ghe/sucDKBD5AUC3AcNftAPXSAear7EONmPKhoOAZ0LStSSDPvUTXNjQZXEc2cgLll3QH+Hztf+A3J4wphP4APX+TkZwELQonS/CYEzDAiylSPDnAIwlD7D4eaR8cYLHcetu0l27bH0at0dM5R46GE20kMNancrw5MceYjC+9ZJ3+2bqnaFKjiycdYB2d/7vthFbKnIHJ715BRZJdKfsFqzc8DtT8wZnyCC2yHdfz2zh5ssyiou3sp7uB3jGeWMHNeZfDnOacPr4TOp+IJsaQ8vDqfWbaS8WwdjDIk0L6I7RsLmkL1G0LfMILe0J4+Aedq/IPDlMpv5rUBVoAAAAASUVORK5CYII=");
          background-size: 18px 19px;
          background-repeat: no-repeat;
          background-position: 0px 12px;
          padding-left: 25px;
        }
      }
      .ban-right {
        width: 388px;
        box-sizing: border-box;
        padding: 35px 50px 20px;
        background: rgba(255, 255, 255, 1);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        .tit {
          text-align: center;
          margin-bottom: 26px;
          font-size: 26px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          color: rgba(255, 136, 64, 1);
          line-height: 45px;
          letter-spacing: 1px;
          span {
            color: rgba(241, 73, 22, 1);
            font-size: 30px;
          }
        }
        .input {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          height: 44px;
          border: 1px solid rgba(191, 191, 191, 1);
          border-radius: 22px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(186, 186, 186, 1);
          margin-bottom: 20px;
          input {
            text-indent: 2em;
            width: 100%;
            height: 100%;
          }
          .imgchoose {
            width: 24px;
            height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .downimg {
              width: 12px;
              height: 13px;
              cursor: pointer;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAgVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABtFS1lAAAAKnRSTlMAYvoM9fAV3wi+HUBbRzDKxToRBDXr6NTPJyLk2IuaK6dStKBsk350Za7Eky/uAAADD0lEQVR42u3c2VLiUBSF4XUEAiGIgDiAMsmgnvd/wA5d0mafhIppGfZOrf+aKvJxwbgOYIwxxhhjjDHGGGOMMcYYY4wxxhhjjDHGGGOMMcaYwkZJEsFGnWHSx7FmPm0NC/Va3vtB97gjLYb+nP9bu4GCEv9V3IHynP9qjoK2/tCgAdU5f6iJgmJvROL8dxHyLf13d4olWUer7Ab+eQSlictco6jYZ3pSKhGOVoSiRs9CovKlUThublFc485nelUokY4E+JGk3YWyShxZyUCzpMQh6sR6JVUcoeRlCjUJR1M6yiWPEyhJOoYo7/7NZ3pQIsk5qksWUFCho1zyKV4++7h6FRxSshSSMSqmxZEmJM0rS9xvLmb9/w+CKgcwr/C0LVLmEBLxRrMkdQ5gq0HiTvG0M7u+RDr6wCkkK5Sm1AHshKSHktQ6gHefrUSi2AFsfi5R7QglHziacgfwcR2JdCxwgnpCskFhBhyh5B0FmXCkkhufaYdcRhzASkhmOHMu/IhqVOLO+aXBrZBskcmUI5TM8S9jjlCyxiFrDiBpXkDiLvH14FBIlvcQncExhehcks9UYtMBjFs+09u9VQfQDyRWHaEk7lh1AIuHM0ncpX+YmQjJoHMWRxcXaPIoJA2rDmD64sXcw6oD6ArJc8OqI5W0Tzpccdf7KVlKniKrDiASktfIqgOIXsX9R1YdQPQkr8CqAxgFEquOcOD1Mv21I8KVkrOox6lVR04yseoIB14PC6uOcEzU6ldx6BoehhKrjpxkbNURzqKaQ6uOnCSx6ggHXjeJVUcqWVYaSTjFk+8qEqd6hL8WkpVZBzCXErOOUNIz6wC2wdzD7nGbmZSUOvQegJKSD7MOYCckG7OOcOD1btYBbKREOIwcECyU7Mw6woHXzKwjnEVtzTpCydysIxx4zc06gJXPFntv5Wh2ycAr61B/7D83JqqHYy+ph2MvqYcjM/Cy87cYR2dR9XDsJfVwAONmPRyppFUPB9Bv+31LmK+zi+PPWzDGGGOMMcYYY4wxxhhjjDHGGGOMMWalP5yJd0BB61pGAAAAAElFTkSuQmCC");
              background-size: cover;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .areacode {
            width: 50px;
            height: 26px;
            color: #bababa;
            border-right: 1px solid #bababa;
            padding-right: 5px;
            margin-left: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
          .selectGrade {
            position: absolute;
            top: 1.2rem;
            left: 0;
            right: 0;
            width: 100%;
            height: auto;
            z-index: 200;
            background-color: #fff;
            box-shadow: 0 6px 10px rgba(64, 63, 65, 0.2);
            border: 1px solid #e3ebf6;
            border-right: none;
            .selectBox {
              display: flex;
              align-items: center;
              width: 100%;
              height: 0.9rem;
              color: #515558;
              border-bottom: 1px solid #e3ebf6;
            }
            .selectBox:last-child {
              border-bottom: none;
            }
            .selectItem {
              flex: 1;
              text-align: left;
              border-right: 1px solid #e3ebf6;
              box-sizing: border-box;
              height: 0.9rem;
              line-height: 0.9rem;
              font-size: 0.45rem;
              padding-left: 0.6rem;
            }
            .selected {
              background-color: #e3ebf6;
            }
          }
        }
        .grade input {
          width: 86%;
        }
        .note-code {
          display: flex;
          justify-content: space-between;
          height: 44px;
          input {
            width: 165px;
            height: 100%;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(191, 191, 191, 1);
            border-radius: 22px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(186, 186, 186, 1);
            text-indent: 2em;
          }
          button {
            width: 105px;
            height: 44px;
            background: rgba(255, 136, 64, 1);
            border-radius: 22px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(243, 243, 243, 1);
          }
        }
        .appointBtn {
          color: #fff;
          width: 296px;
          height: 54px;
          background: linear-gradient(
            270deg,
            rgba(248, 120, 51, 1) 0%,
            rgba(255, 139, 53, 1) 100%
          );
          box-shadow: 0px 10px 18px 0px rgba(252, 114, 46, 0.26);
          border-radius: 27px;
          margin-top: 15px;
          font-size: 20px;
        }
        .customService {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          span {
            color: #888888;
            font-size: 18px;
            margin-left: 11px;
          }
        }
        .selectMenu {
          position: absolute;
          width: 260px;
          height: 314px;
          top: 345px;
          right: 42px;
          border: 1px solid #e3ebf6;
          background-color: #fff;
          padding: 10px;
          .gradeList {
            width: 280px;
            height: 288px;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            & div {
              width: 280px;
              line-height: 31px;
              text-align: left;
              cursor: pointer;
            }
          }
        }
        .message {
          position: absolute;
          top: 30%;
          left: 80%;
          padding: 0 17px;
          box-sizing: border-box;
          width: 220px;
          height: 46px;
          line-height: 46px;
          font-size: 16px;
          color: #fefefe;
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 23px;
          z-index: 600;
          overflow: hidden;
          text-align: center;
        }
      }
    }
    .banner-bg {
      width: 100%;
      display: block;
      max-width: 1920px;
      height: 640px;
      margin: 0 auto;
    }
  }
  .video {
    margin-bottom: 110px;
    .video-content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      height: 630px;
      .video-bg {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          display: block;
        }
        .bg {
          width: 100%;
          height: 100%;
        }
        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100px;
          height: 100px;
        }
      }
      .video-player {
        width: 100%;
      }
      .hide {
        display: none;
      }
    }
  }
  .platform {
    background: rgba(246, 246, 249, 1);
    padding-bottom: 140px;
    .platform-content {
      width: 100%;
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
    }
    .calligraphy {
      width: 22%;
      img {
        display: block;
        height: 108px;
        margin: 0 auto;
        margin-bottom: 55px;
      }
      &:nth-child(1) {
        img {
          width: 106px;
        }
      }
      &:nth-child(2) {
        img {
          width: 98px;
        }
      }
      &:nth-child(3) {
        img {
          width: 108px;
        }
      }
      &:nth-child(4) {
        img {
          width: 107px;
        }
      }
      .tit {
        text-align: center;
        font-size: 26px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        color: rgba(68, 65, 68, 1);
        line-height: 45px;
        margin-bottom: 20px;
      }
      p {
        width: 205px;
        margin: 0 auto;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(112, 108, 112, 1);
        line-height: 32px;
      }
    }
  }
  .system {
    color: #fff;
    text-align: center;
    padding-bottom: 150px;
    .system-content {
      padding: 0 20px;
      box-sizing: border-box;
      .grade {
        width: 180px;
        background: rgba(255, 136, 64, 1);
        border-radius: 8px;
        font-size: 30px;
        line-height: 180px;
        writing-mode: vertical-lr;
        letter-spacing: 1px;
      }
      .lower-grade {
        display: flex;
        width: 100%;
        .lower {
          height: 490px;
        }
      }
      .higher-grade {
        display: flex;
        width: 100%;
        .higher {
          height: 790px;
        }
      }
      .class-level {
        margin: 0 10px;
        width: 160px;
        .level {
          display: block;
          height: 90px;
          border-radius: 8px;
          font-size: 34px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          line-height: 90px;
          margin-bottom: 10px;
        }
      }
      .level-content {
        .content {
          height: 90px;
          padding: 15px 0px 15px 30px;
          box-sizing: border-box;
          margin-bottom: 10px;
          border-radius: 8px;
          text-align: left;
          display: flex;
          align-content: space-between;
          flex-wrap: wrap;
          p {
            width: 100%;
            font-size: 20px;
          }
        }
        .content1 {
          width: 600px;
        }
        .content2 {
          width: 610px;
        }
        .content3 {
          width: 620px;
        }
        .content4 {
          width: 630px;
        }
        .content5 {
          width: 640px;
        }
        .content6 {
          width: 650px;
        }
        .content7 {
          width: 660px;
        }
        .content8 {
          width: 670px;
        }
        .content9 {
          width: 680px;
        }
        .content10 {
          width: 690px;
        }
        .content11 {
          width: 700px;
        }
        .content12 {
          width: 710px;
        }
        .content13 {
          width: 720px;
        }
      }
    }
  }
  .class-pattern {
    padding-bottom: 140px;
    img {
      width: 1031px;
      height: 355px;
      display: block;
      margin: 0 auto;
    }
  }
  .teacher {
    background: rgba(246, 246, 249, 1);
    padding-bottom: 80px;
    .teacher-team {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      .content {
        width: 260px;
        img {
          display: block;
          width: 260px;
          height: 260px;
        }
        .desc {
          width: 100%;
          height: 145px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 32px 50px 0px rgba(161, 161, 172, 0.18);
          border-radius: 0px 0px 16px 16px;
          box-sizing: border-box;
          padding: 0 12px 20px;
          position: relative;
          .name {
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            width: 180px;
            height: 50px;
            background: rgba(255, 136, 64, 1);
            border-radius: 25px;
            font-size: 26px;
            font-family: MicrosoftYaHei-Bold;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            line-height: 50px;
            text-align: center;
          }
          p {
            text-align: left;
            font-size: 16px;
            font-family: PingFang-SC-Regular;
            font-weight: 400;
            color: rgba(136, 136, 136, 1);
            line-height: 24px;
          }
          p.tit {
            text-align: center;
            color: rgba(68, 61, 55, 1);
            line-height: 22px;
            padding-top: 33px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  .student-pro {
    padding-bottom: 95px;
    height: 784px;
    .pro-content {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .pro {
        display: flex;
        padding: 23px 0;
        box-sizing: border-box;
        width: 350px;
        height: 466px;
        background: rgba(249, 236, 229, 1);
        box-shadow: 0px 32px 50px 0px rgba(161, 161, 172, 0.18);
        border-radius: 16px;
        position: relative;
        .showtop {
          display: block;
          margin: 0 auto;
          width: 320px;
          height: 420px;
          border-radius: 8px;
        }
        .stu {
          position: absolute;
          bottom: -68px;
          right: 0;
          left: 0;
          width: 100%;
          padding: 0 25px 20px;
          box-sizing: border-box;
          background: #F9ECE5;
          display: flex;
          border-radius: 0 0 16px 16px;
          .avatar {
            width: 53px;
            height: 53px;
            padding: 2px 0;
            margin-right: 15px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .stu-detail {
            flex: 1;
            height: 57px;
            display: flex;
            align-content: space-between;
            color: rgba(255, 255, 255, 1);
            flex-wrap: wrap;
            span {
              width: 100%;
              text-align: left;
              display: block;
            }
            .name {
              font-size: 28px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              line-height: 38px;
              color: #000;
            }
            .stu-teacher {
              font-size: 16px;
              color: #4D4D4D;
            }
          }
          .stu-time{
              font-size: 12px;
              color: #4D4D4D;
              display: flex;
              align-items: center;
            }
        }
      }
    }
  }
  .feedBack {
    background: rgba(246, 246, 249, 1);
    overflow: hidden;
    .title-template {
      padding-top: 0;
      position: relative;
      top: 70px;
    }
  }
  .message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0.5rem;
    box-sizing: border-box;
    width: auto;
    max-width: 90vw;
    height: 1.16rem;
    line-height: 1.16rem;
    font-size: 0.42rem;
    color: #fefefe;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.6rem;
    z-index: 600;
    overflow: hidden;
  }
  .orderTip {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 94px;
    padding-bottom: 119px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    width: 605px;
    height: 340px;
    z-index: 1000;
    border: #e3ebf6 1px solid;
    .orderTitle {
      margin-bottom: 60px;
      height: 47px;
      line-height: 47px;
      font-size: 47px;
      color: #71c5ff;
    }
    .orderMsg {
      width: 492px;
      height: 20px;
      line-height: 60px;
      color: #515558;
      font-size: 20px;
      text-align: center;
    }
    .closeOrder {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAhFBMVEUAAADJycm/v7++vr6+vr7Hx8e/v7/BwcG/v7/Dw8O+vr6+vr6/v7+/v7+/v7+/v7+/v7+/v7+/v7/AwMC/v7++vr6/v7/AwMC/v7+/v7+/v7/CwsK/v7+/v7+/v7/AwMDBwcG/v7+/v7+/v7+/v7++vr7AwMC+vr6+vr6+vr6+vr6/v7+9+e0GAAAAK3RSTlMABvgY3w2wE7wKxfHs1MuAuLSkIuSQdx7YnIgn56tcPzVxZGHPyUeYU0zNpVd7SgAAAblJREFUOMu9lNl2ozAQRI2Q2SQZDJjdeIszydT//9/ESmNJBDLn5CF6aSTu6Sq6UW9+YYX9+Rz/D/LeAobHGpL0/E2ugwDAo0PXBgqQXbyCMbDsGtK2qBSG2wLXl2Ct7xyNJaLtnHsXSPovljs0tXt0AnJvQWdksrD3hUDlACYD45afuEG3VooLlPn4FMF60Y5GzGcs1DJ56NQrf9VBiClliwOpNJYfv8FFP1RIqQiMUaYcxrnPkdN7KT8fXo3DoyaJO06nf3CiOD71AiKJo0xkTTHTJy/TpM9JhbwpHUWpg0XWHJndJy70K9yd/kbgJSKnn3c8tsWs2l4C4iznxQIYf4BJPANrLb2bcVk2I3fwvnzMNvnQ9SKXHKQOClvD7bS/B2kdkuYBo8XttYq3x+5J3qjZJwQT96K5ObkHXV3J/IkLNOeSIZPP/7a1/19DpmStmhQFen1zbm7lr/VnwvIpU+Gv981VGE2RFfI1rnP8+BzpMldBOU0q5OIA8HLI2aiom4WR0idmpJg+RfMh5bdsPqSoU4M19sJrxvDytmw87iQAwXd3LgC2f9+sr3MaqZKxQUWXcPOz9Q93ASPBvzinwwAAAABJRU5ErkJggg==");
      background-size: 40px 40px;
      cursor: pointer;
    }
  }
  .brush-btn {
    position: fixed;
    right: 10px;
    width: 166px;
    height: 186px;
    bottom: 1.8rem;
    cursor: pointer;
  }
}
.slide1-fade-enter-active {
  transition: all 0.5s ease;
}
.slide1-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide1-fade-enter,
.slide1-fade-leave-to {
  opacity: 0;
}
</style>
