import { render, staticRenderFns } from "./writeClass.vue?vue&type=template&id=225c5adf&scoped=true"
import script from "./writeClass.vue?vue&type=script&lang=js"
export * from "./writeClass.vue?vue&type=script&lang=js"
import style0 from "./writeClass.vue?vue&type=style&index=0&id=225c5adf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225c5adf",
  null
  
)

export default component.exports